import React from "react";
import AstrazioniSonore from "./1_astrazioni_sonore";
import OnTheWall from "./2_on_the_wall";
import Isola from "./3_isola";
import ChoiceExists from "./4_choice_exists";
import Origins from "./5_origins";
import ArtSchool from "./6_art_school";
import Exness from "./7_exness";
import FutureOfRegions from "./8_future_of_regions";
import PremioMontecosaro from "./9_premio_montecosaro";
import Vernissage from "./10_vernissage";
import NoaGallery from "./11_noa_gallery";
import CleanSheet from "./12_clean_sheet";
import RightToLife from "./13_right_to_life";
import RomanSummer from "./14_roman_summer";
import NotarteFestival from "./15_notarte_festival";
import Ventuno from "./16_ventuno";
import YoutubePage from "./17_youtube";
import TEDX from "./18_tedx";
import PortugalVR from "./19_portugal_vr";
import Nicolaquinto from "./20_nicolaquinto";

const projectsMap = {
  astrazioni_sonore: AstrazioniSonore,
  on_the_wall: OnTheWall,
  isola: Isola,
  choice_exists: ChoiceExists,
  origins: Origins,
  art_school: ArtSchool,
  exness: Exness,
  future_of_regions: FutureOfRegions,
  premio_montecosaro: PremioMontecosaro,
  vernissage: Vernissage,
  noa_gallery: NoaGallery,
  clean_sheet: CleanSheet,
  right_to_life: RightToLife,
  roman_summer: RomanSummer,
  notarte_festival: NotarteFestival,
  ventuno: Ventuno,
  youtube: YoutubePage,
  tedx: TEDX,
  portugal_vr: PortugalVR,
  nicolaquinto: Nicolaquinto,
};

const ProjectPageRenderer = (name) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);
  return projectsMap[name]();
};

export default ProjectPageRenderer;
