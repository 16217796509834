import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  align-items: center;
  text-align: center;
  a {
    /* text-decoration: none; */
    font-weight: bold;
    color: #808080;
  }
`;

const Contact = () => {
  return (
    <Container>
      <p>mariiaplekhova@gmail.com</p>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://instagram.com/mplehova?igshid=NTc4MTIwNjQ2YQ=="
      >
        Inst: mplehova
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.facebook.com/MariaPlekhova"
      >
        Facebook: Mariia&nbsp;Plekhova
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.spatial.io/s/Mariia-Plekhovas-gallery-64b6f19714fbde13fd5f9467?share=1368816790973390020"
      >
        Virtual Gallery
      </a>
    </Container>
  );
};

export default Contact;
