import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/20", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Nicolaquinto() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>MARIIA PLEKHOVA & NICOLAQUINTO</b>
        </p>
        <p>Life is a work of art!</p>
        <p>Coming soon</p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Nicolaquinto;
